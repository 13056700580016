import Vue from "vue";

// axios
import axios from "axios";
import store from "../store/index";
import { mapGetters } from "vuex";

import * as rax from "retry-axios";
import eventBus from "./bus";

computed: mapGetters(["isAuthenticated"]);
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BASE_URL + "/",
  // timeout: 3000,
  headers: {
  
    "Content-Type": "application/json, multipart/form-data",
   
    "Accept-language": "ar",
  },
});
  // 'Access-Control-Allow-Headers' : 'Content-Type, X-Auth-Token, Authorization, Origin',
    // 'Authorization': 'Bearer ' + localStorage.getItem('token'),
    // "Access-Control-Allow-Headers": "Authorization",
     // 'Access-Control-Allow-Origin': '*',
    // "Access-Control-Allow-Origin": process.env.VUE_APP_BASE_URL + "/",
    // 'Content-Type': 'multipart/form-data'
    // "Access-Control-Allow-Methods": "POST, PUT,GET,DELETE,OPTIONS",.
    
// axiosIns.defaults.raxConfig = {
//     instance: axiosIns
// };

// const interceptorId = rax.attach(axiosIns);
// axiosIns.get('test')
//     .catch(err => {

//     })
axiosIns.interceptors.response.use(
  function(response) {
    eventBus.$emit("after-response");

    return response;
  },
  function(error) {
    eventBus.$emit("response-error");
    if (error.response && error.response.status === 401 && !error.config.__isRetryRequest) {
        store.dispatch("axiosLogout");
      }

    return Promise.reject(error);
  }
);

axiosIns.interceptors.request.use((config) => {
  eventBus.$emit("before-request");
  // this.$loading(true)
  const access_token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${access_token}`;
  return config;
}),
  (error) => {
    eventBus.$emit("request-error");
    return Promise.reject(error);
  };

Vue.prototype.$http = axiosIns;
//export default axiosIns
export default axiosIns;
